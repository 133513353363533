import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getClientAPI } from "../service/UserService";
import { updateCommissionAPI } from "../service/AuthService";
import toast from "react-hot-toast";

export default function ClientLimitUpdate() {
  const userData = useSelector((state) => state.account.userData);
  const params = useParams();
  const [search, setSearch] = useState("");
  const [clientData, setClientData] = useState({});

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(10);
  const location = useLocation();
  const [userType, setUserType] = useState(params?.userType?.toUpperCase());
  const [id, setId] = useState(location?.state?.userName || "");
  const [updateCommission, setUpdateCommission] = useState({});

  const getClient = async () => {
    const { response } = await getClientAPI(
      search,
      currentPage,
      size,
      id,
      params?.userType.toUpperCase()
    );
    let response_ = response;
    if (response_) {
      response_.childrenUserResponseDTOS = response_.childrenUserResponseDTOSPage.content.sort(
        (a, b) => a.uid - b.uid
      );
      setCurrentPage(response_.childrenUserResponseDTOSPage.number);
      setTotalPages(response_.childrenUserResponseDTOSPage.totalPages);
      setTotalElements(response_.childrenUserResponseDTOSPage.totalElements);
      setSize(response_.childrenUserResponseDTOSPage.size);
    }
    setClientData(response_);
  };
  const handleUpdateCommission = async (type) => {
    let updatedLimit;
    if (type === "Minus") {
      updatedLimit = -updateCommission.currentLimit;
    } else {
      updatedLimit = updateCommission.currentLimit;
    }
    try {
      const { response, code } = await updateCommissionAPI({
        ...updateCommission,
        currentLimit: updatedLimit,
      });
      if (code === 200) {
        toast.success("Limit Updated Successfully");
        getClient();
        window.location.reload();
        setUpdateCommission({
          ...updateCommission,
          currentLimit: "",
        });
      } else {
        toast.error(response);
      }
    } catch (e) {
      toast.error("Something went wrong");
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    getClient();
  }, [location.state, currentPage]);
  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-all-updte-limit
        _nghost-ng-c2116192893=""
        className="ng-star-inserted"
      >
        <div _ngcontent-ng-c2116192893="" className="page_header">
          <h1 _ngcontent-ng-c2116192893="" className="page_heading">
            {userType} Limit
          </h1>
          <div _ngcontent-ng-c2116192893="" className="breadcrumb_block">
            <nav _ngcontent-ng-c2116192893="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c2116192893="" className="breadcrumb">
                <li _ngcontent-ng-c2116192893="" className="breadcrumb-item">
                  <a _ngcontent-ng-c2116192893="" href="#/dashboard">
                    Home
                  </a>
                </li>
                <li _ngcontent-ng-c2116192893="" className="breadcrumb-item">
                  <a _ngcontent-ng-c2116192893="">{userType} Details</a>
                </li>
                <li
                  _ngcontent-ng-c2116192893=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  {userType} Limit
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          _ngcontent-ng-c2116192893=""
          className="Super_master_details_section agentledger_block"
        >
          <div _ngcontent-ng-c2116192893="" className="custom_table">
            <div _ngcontent-ng-c2116192893="" className="table-responsive">
              <form
                _ngcontent-ng-c2116192893=""
                noValidate=""
                className="ng-untouched ng-pristine ng-invalid"
              >
                <table _ngcontent-ng-c2116192893="" className="table">
                  <thead _ngcontent-ng-c2116192893="">
                    <tr _ngcontent-ng-c2116192893="">
                      <th _ngcontent-ng-c2116192893="">SNo.</th>
                      <th _ngcontent-ng-c2116192893="">Client</th>
                      <th _ngcontent-ng-c2116192893="">Current Limit</th>
                      <th _ngcontent-ng-c2116192893="">Add/Minus Limit</th>
                      <th _ngcontent-ng-c2116192893="">
                        {userType == "ADMIN"
                          ? "Super Admin"
                          : userType == "SUB_ADMIN"
                          ? "Admin"
                          : userType == "MASTER"
                          ? "Sub Admin"
                          : userType == "SUPER_MASTER"
                          ? "Master"
                          : userType == "AGENT"
                          ? "Super Master"
                          : userType == "CLIENT"
                          ? "Agent"
                          : ""}{" "}
                        Limit
                      </th>
                      <th _ngcontent-ng-c2116192893="">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody _ngcontent-ng-c2116192893="">
                    {clientData?.childrenUserResponseDTOS?.map(
                      (client, index) => (
                        <tr
                          _ngcontent-ng-c2116192893=""
                          className="ng-star-inserted"
                        >
                          <td _ngcontent-ng-c2116192893="">{index}</td>
                          <td _ngcontent-ng-c2116192893="">
                            <input
                              _ngcontent-ng-c2116192893=""
                              type="text"
                              readOnly=""
                              disabled
                              id="name"
                              name="name"
                              value={client.username + " " + client?.name}
                              className="form-control"
                            />
                          </td>
                          <td _ngcontent-ng-c2116192893="">
                            <input
                              _ngcontent-ng-c2116192893=""
                              type="number"
                              readOnly=""
                              id="name"
                              disabled
                              value={parseFloat(
                                client?.balance + client?.liability
                              ).toFixed(2)}
                              name="name"
                              className="form-control"
                            />
                          </td>
                          <td _ngcontent-ng-c2116192893="">
                            <input
                              _ngcontent-ng-c2116192893=""
                              type="number"
                              id="name"
                              formcontrolname="addChips"
                              name="name"
                              onChange={(e) => {
                                setUpdateCommission({
                                  ...updateCommission,
                                  id: client?.uid,
                                  currentLimit: e.target.value,
                                });
                              }}
                              className="form-control ng-untouched ng-pristine ng-invalid"
                            />
                          </td>
                          <td _ngcontent-ng-c2116192893="">
                            <input
                              _ngcontent-ng-c2116192893=""
                              readOnly=""
                              type="number"
                              disabled
                              id="name"
                              name="name"
                              value={client?.parentLimit}
                              className="form-control"
                            />
                          </td>
                          <td
                            _ngcontent-ng-c2116192893=""
                            style={{ display: "flex" }}
                          >
                            <button
                              _ngcontent-ng-c2116192893=""
                              type="button"
                              onClick={() => handleUpdateCommission("Plus")}
                              className="btn bg_plus"
                            >
                              Add
                            </button>
                            <button
                              _ngcontent-ng-c2116192893=""
                              type="button"
                              onClick={() => handleUpdateCommission("Minus")}
                              className="btn red_plus ms-2"
                            >
                              Minus
                            </button>
                          </td>
                        </tr>
                      )
                    )}

                    {/**/}
                  </tbody>
                </table>
                <div
                  _ngcontent-ng-c2116192893=""
                  className="pagination-cls ng-star-inserted"
                >
                  <pagination-controls
                    _ngcontent-ng-c2116192893=""
                    previouslabel="Previous"
                    nextlabel="Next"
                    screenreaderpaginationlabel="Pagination"
                    screenreaderpagelabel="page"
                    screenreadercurrentlabel="You're on page"
                  >
                    <pagination-template>
                      <nav role="navigation" aria-label="Pagination">
                        <ul className="ngx-pagination ng-star-inserted">
                          <li
                            className={`pagination-previous ${
                              currentPage === 0 ? "disabled" : ""
                            } ng-star-inserted`}
                          >
                            {/**/}
                            <span
                              onClick={() => {
                                setCurrentPage(currentPage - 1);
                              }}
                              aria-disabled={
                                currentPage === 0 ? "true" : "false"
                              }
                              className="ng-star-inserted"
                            >
                              {" "}
                              Previous{" "}
                              <span className="show-for-sr">page</span>
                            </span>
                            {/**/}
                          </li>
                          {/**/}
                          <li className="small-screen">
                            {" "}
                            {1 + currentPage * size} / {totalElements}{" "}
                          </li>
                          <li className="current ng-star-inserted">
                            {/**/}
                            <span
                              aria-live="polite"
                              className="ng-star-inserted"
                            >
                              <span className="show-for-sr">
                                You're on page{" "}
                              </span>
                              <span>{currentPage + 1}</span>
                            </span>
                            {/**/}
                            {/**/}
                          </li>
                          {Array.from({ length: totalPages }, (_, i) => (
                            <li className={`ng-star-inserted `}>
                              <a
                                onClick={() => setCurrentPage(i)}
                                tabIndex={0}
                                className={`ng-star-inserted ${
                                  currentPage === i ? "current" : ""
                                }`}
                              >
                                <span className="show-for-sr">page </span>
                                <span>{i + 1}</span>
                              </a>
                              {/**/}
                              {/**/}
                            </li>
                          ))}

                          <li className="pagination-next ng-star-inserted">
                            <a
                              tabIndex={0}
                              onClick={() => setCurrentPage(currentPage + 1)}
                              className="ng-star-inserted"
                            >
                              {" "}
                              Next <span className="show-for-sr">page</span>
                            </a>
                            {/**/}
                            {/**/}
                          </li>
                          {/**/}
                        </ul>
                        {/**/}
                      </nav>
                    </pagination-template>
                  </pagination-controls>
                </div>
                {/**/}
              </form>
            </div>
          </div>
        </div>
      </app-all-updte-limit>
      {/**/}
    </div>
  );
}
