import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getAdminPlExposureCricketAPI,
  getAllMatchesOddsCricketAPI,
  getBetActiveDeactiveCricketAPI,
  getClientBetsCricketAPI,
  getCricketEventDetailsAPI,
  getCricketOddsByMatchIdAPI,
  getSettledFancyAPI,
} from "../service/cricket";
import {
  getLiveStreamingUrlAPI,
  getSportsScoreAPI,
  getBookMakerPositionAPI,
} from "../service/AuthService";
import moment from "moment";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import Loader from "./components/Loader";

export default function MatchPosition() {
  const userData = useSelector((state) => state.account.userData);
  const [matchOdds, setMatchOdds] = useState(null);
  const [matchDetails, setMatchDetails] = useState(null);
  const location = useLocation();
  const [betFilter, setBetFilter] = useState("BOOKMAKER_ODDS_1");
  const [userBetStatus, setUserBetStatus] = useState(false);
  const [fancyBetStatus, setFancyBetStatus] = useState(false);
  const [settledFancy, setSettledFancy] = useState({});
  const [matchData, setMatchData] = useState(null);
  const [clientBets, setClientBets] = useState([]);
  const [betStatus, setBetStatus] = useState("MATCHED");
  const [bookmakerPosition, setBookmakerPosition] = useState([]);
  const [gameSettings_, setGameSettings_] = useState({});
  const [showLiveTv, setShowLiveTv] = useState(false);
  const [channelId, setChannelId] = useState(null);
  const [showLadder, setShowLadder] = useState(false);
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(false);
  const getAllMatches = async () => {
    const { response } = await getAllMatchesOddsCricketAPI();
    setMatches(response);
  };

  useEffect(() => {
    getAllMatches();
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  var gameType = "cricket";
  var eventId = params?.id;
  var gameSettings = useSelector(
    (state) => state.gameData[gameType + "Settings"]
  );
  if (gameSettings && gameSettings[eventId]) {
    gameSettings = gameSettings[eventId];
  }

  const getBookMakerposition = async (uid) => {
    const { response, code } = await getBookMakerPositionAPI(eventId, uid);
    if (code == 200) {
      setBookmakerPosition(response);
    }
  };

  const getGameEventDetails = useCallback(async () => {
    const { response } = await getCricketEventDetailsAPI(eventId);
    setMatchDetails(response);
    return response;
  }, [eventId, gameType]);
  const getSettledFancy = async () => {
    const { response } = await getSettledFancyAPI(eventId);
    setSettledFancy(response);
  };
  useEffect(() => {
    getGameEventDetails();

    setGameSettings_(gameSettings[eventId]);
  }, [location.pathname]);

  const getCricketPlExposure = async () => {
    const { response, code } = await getAdminPlExposureCricketAPI(eventId);
    if (code == 200) {
      setMatchData(response);
    }
  };
  const getClientBets = async (betStatus_, isFancy) => {
    const { response } = await getClientBetsCricketAPI(
      eventId,
      location?.state?.isBookmaker == true
        ? false
        : location?.state?.isBookmaker == false
        ? true
        : false,
      betStatus_ ? betStatus_ : betStatus
    );
    if (response) {
      setClientBets(response);
    }
  };
  const getBetActiveDeactive = async () => {
    const { response } = await getBetActiveDeactiveCricketAPI(eventId);
    if (response) {
      setUserBetStatus(response?.betLock);
      setFancyBetStatus(response?.fancyLock);
    }
  };
  const getCricketOddsByMatchId = useCallback(async () => {
    const { response, code } = await getCricketOddsByMatchIdAPI(eventId);
    if (code != 200) {
      toast.error("Something went wrong");
      window.location.href = "/";
      return;
    }
    let response_ = {};
    response_[eventId] = response;
    setMatchOdds(response);
    dispatch({
      type: "gameData/setCricketOdds",
      payload: response_,
    });
  }, [eventId]);

  useEffect(() => {
    if (eventId) {
      getCricketPlExposure();
      getClientBets();
      let interval = setInterval(() => {
        getCricketPlExposure();
        getClientBets();
        getBetActiveDeactive();
        getSettledFancy();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [eventId, location?.state?.isBookmaker]);
  useEffect(() => {
    let interval = setInterval(() => {
      getCricketOddsByMatchId();
    }, 800);
    return () => clearInterval(interval);
  }, [eventId]);

  const getLiveStreamingUrl = async () => {
    setLoading(true);
    let gameId = await getGameEventDetails();
    const { response } = await getLiveStreamingUrlAPI(gameId?.eventId);
    setChannelId(response);
    setLoading(false);
  };
  useEffect(() => {
    getLiveStreamingUrl();
  }, [eventId]);

  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      {loading && <Loader />}
      <app-match-detail
        _nghost-ng-c2652263913=""
        className="ng-star-inserted"
      >
        <div _ngcontent-ng-c2652263913="" className="page_header">
          <h1 _ngcontent-ng-c2652263913="" className="page_heading">
            Match &amp; Session Position
          </h1>
          <div _ngcontent-ng-c2652263913="" className="breadcrumb_block">
            <nav _ngcontent-ng-c2652263913="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c2652263913="" className="breadcrumb">
                <li _ngcontent-ng-c2652263913="" className="breadcrumb-item">
                  <a _ngcontent-ng-c2652263913="" href="#">
                    Home
                  </a>
                </li>

                <li
                  _ngcontent-ng-c2652263913=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  Match &amp; Session Position
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          _ngcontent-ng-c2652263913=""
          className="Super_master_details_section agentledger_block match_details_mobile"
        >
          <div _ngcontent-ng-c2652263913="" className="match_detail_header">
            <h5 _ngcontent-ng-c2652263913="">{matchDetails?.eventName} </h5>
            <div _ngcontent-ng-c2652263913="" className="dtl_headr">
              <button
                _ngcontent-ng-c2652263913=""
                onClick={() => setShowLiveTv(!showLiveTv)}
                className="btn btn-primary live_tv_btn"
              >
                Live TV
              </button>
              <div _ngcontent-ng-c2652263913="" className="dropdown">
                <a
                  _ngcontent-ng-c2652263913=""
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className="btn btn-secondary dropdown-toggle"
                >
                  {" "}
                  Matches{" "}
                </a>
                <ul
                  _ngcontent-ng-c2652263913=""
                  aria-labelledby="dropdownMenuLink"
                  className="dropdown-menu"
                >
                  {matches
                    ?.filter((item) => item?.inPlay)
                    ?.map((item) => (
                      <li
                        _ngcontent-ng-c2652263913=""
                        className="dropdown-item"
                        onClick={() => {
                          navigate(`/game/matchPosition/${item?.cricketId}`);
                        }}
                      >
                        {item?.eventName}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          {showLiveTv && (
            <div _ngcontent-ng-c2652263913="" class="toggletv">
              <iframe
                _ngcontent-ng-c2652263913=""
                id="cricketScore"
                title="Match Score"
                class="iframestyle iframeheightControll"
                style={{ width: "100%", height: "300px", background: "#000" }}
                src={`https://video.proexch.in/tv/static?chid=${channelId}`}
              />
            </div>
          )}
          <div
            _ngcontent-ng-c2652263913=""
            className="scoreboard ng-star-inserted"
            style={{
              padding: "0px",
              height: "auto",
            }}
          >
            <iframe
              style={{
                padding: "0 0px",
                height: "100px",
                width: "100%",
              }}
              src={`https://www.7777exch.com/ScoreCardNew1.aspx?EventID=${
                matchDetails?.eventId
              }`}
            />
          </div>
          {/**/}
          {/**/}
          {/**/}
          <div _ngcontent-ng-c2652263913="" className="row">
            <div
              _ngcontent-ng-c2652263913=""
              className="col-sm-12 col-md-12 col-lg-12 col-xxl-6"
            >
              <div className="sc-jiXIMO kBPyVG snipcss-eSS8v">
                <div className="sc-kvqHdt sc-kmzTNl cKHfpx kfJPwG">
                  <div className="sc-eRerso foOcVP">
                    <div className="sc-hcBGXp kjYtWL">
                      <button className="sc-cDRvxq bCKsOU">
                        <div
                          name="star-red-inactive"
                          className="sc-fvNpTx fbPdEN"
                        >
                          <span
                            className="lazy-load-image-background none lazy-load-image-loaded style-EgvzT"
                            id="style-EgvzT"
                          >
                            <img
                              src="https://opt-v3-files.raksahb.com/static/snk/brand/31/icon/png/star-red-inactive.png"
                              height="100%"
                              width="100%"
                            />
                          </span>
                        </div>
                      </button>
                      <div
                        style={{
                          fontSize: "x-small",
                        }}
                        className="sc-hYzwfJ iAODgt"
                      >
                        Match Odds{" "}
                      </div>
                    </div>
                  </div>
                  <div className="sc-exLqqE sc-czeHBz eTlYgY bbKLbo">
                    <div
                      style={{
                        paddingRight: "40px",
                        marginLeft: "70px",
                        fontWeight: "bolder",
                      }}
                      className="sc-bWUTjH ddDXjD"
                    >
                      LAGAI
                    </div>
                    <div
                      style={{
                        marginLeft: "15px",
                        fontWeight: "bolder",
                      }}
                      className="sc-keVxVi jbTwHB"
                    >
                      KHAI
                    </div>
                  </div>
                </div>
                {matchOdds &&
                  matchOdds?.bookMakerOdds[0]?.bm1?.oddDetailsDTOS.map(
                    (match, index) => (
                      <div className="sc-kvqHdt sc-kLezXb sc-gHGAhJ cKHfpx bJSTZQ cPBUte">
                        <div
                          style={{
                            marginRight: "10px",
                          }}
                          className="sc-dFUPQr sc-feyilL fNrpGt grQHZb"
                        >
                          <div>
                            <div className="sc-dRqsoR hEgtIY">
                              <h1>{match?.runnerName}</h1>
                            </div>
                            <div
                              style={{
                                color:
                                  parseFloat(
                                    matchData
                                      ?.find(
                                        (item) =>
                                          item.marketIdExternal ==
                                          matchOdds?.bookMakerOdds[0]?.bm1
                                            ?.marketId
                                      )
                                      ?.runnerPlExposureDTOS?.find(
                                        (pl) =>
                                          pl.selectionId == match?.selectionId
                                      )?.exposure
                                  ) > 0
                                    ? "green"
                                    : "red",
                              }}
                            >
                              {parseFloat(
                                matchData
                                  ?.find(
                                    (item) =>
                                      item.marketIdExternal ==
                                      matchOdds?.bookMakerOdds[0]?.bm1
                                        ?.marketId
                                  )
                                  ?.runnerPlExposureDTOS?.find(
                                    (pl) =>
                                      pl.selectionId == match?.selectionId
                                  )?.exposure
                                  ? matchData
                                      ?.find(
                                        (item) =>
                                          item.marketIdExternal ==
                                          matchOdds?.bookMakerOdds[0]?.bm1
                                            ?.marketId
                                      )
                                      ?.runnerPlExposureDTOS?.find(
                                        (pl) =>
                                          pl.selectionId == match?.selectionId
                                      )?.exposure
                                  : 0
                              ).toFixed(2)}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          className="sc-exLqqE sc-czeHBz eTlYgY bbKLbo"
                        >
                          <i
                            onClick={() => {
                              navigate(
                                `/game/matchPosition/${eventId}/${
                                  match?.runnerName
                                }/${match?.marketId}`,
                                {
                                  state: {
                                    isBookmaker: true,
                                    runnerName: match?.runnerName,
                                  },
                                }
                              );
                            }}
                            className="fa fa-info-circle"
                          />
                          <div type="BACK" className="sc-kRjaKC jwPIDK">
                            <div type="BACK" className="sc-kWvIdS QtCGw">
                              <div type="BACK" className="sc-cVQvvw jtgJVn">
                                {match?.back1}
                              </div>
                              <div type="BACK" className="sc-loRuot gNLGlZ">
                                {match?.backSize1}
                              </div>
                            </div>
                          </div>
                          <div type="LAY" className="sc-kRjaKC hoLQnR">
                            <div type="LAY" className="sc-kWvIdS fvpuXi">
                              <div type="LAY" className="sc-cVQvvw jtgJVn">
                                {match?.lay1}
                              </div>
                              <div type="LAY" className="sc-loRuot gNLGlZ">
                                {match?.laySize1}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
            <div
              _ngcontent-ng-c2652263913=""
              className="col-sm-12 col-md-12 col-lg-12 col-xxl-6"
            >
              <div className="sc-jiXIMO kBPyVG snipcss-eSS8v">
                <div className="sc-kvqHdt sc-kmzTNl cKHfpx kfJPwG">
                  <div className="sc-eRerso foOcVP">
                    <div className="sc-hcBGXp kjYtWL">
                      <button className="sc-cDRvxq bCKsOU">
                        <div
                          name="star-red-inactive"
                          className="sc-fvNpTx fbPdEN"
                        >
                          <span
                            className="lazy-load-image-background none lazy-load-image-loaded style-EgvzT"
                            id="style-EgvzT"
                          >
                            <img
                              src="https://opt-v3-files.raksahb.com/static/snk/brand/31/icon/png/star-red-inactive.png"
                              height="100%"
                              width="100%"
                            />
                          </span>
                        </div>
                      </button>
                      <div className="sc-hYzwfJ iAODgt">Session </div>
                    </div>
                  </div>
                  <div className="sc-exLqqE sc-czeHBz eTlYgY bbKLbo">
                    <div
                      style={{
                        paddingRight: "0px",
                        marginLeft: "90px",
                        fontWeight: "bolder",
                        color: "rgb(224, 60, 60)",
                      }}
                      className="sc-bWUTjH ddDXjD"
                    >
                      No
                    </div>
                    <div
                      style={{
                        paddingLeft: "50px",
                        fontWeight: "bolder",
                        color: "rgb(3, 117, 204)",
                      }}
                      className="sc-keVxVi jbTwHB"
                    >
                      Yes
                    </div>
                  </div>
                </div>
                {matchOdds?.fancyOdds
                  ?.find(
                    (item) => item?.gameType?.toLocaleLowerCase() == "normal"
                  )
                  ?.oddDetailsDTOS?.map((match) => (
                    <div className="sc-kvqHdt sc-kLezXb sc-gHGAhJ cKHfpx bJSTZQ cPBUte">
                      <div
                        style={{
                          marginRight: "10px",
                        }}
                        className="sc-dFUPQr sc-feyilL fNrpGt grQHZb"
                      >
                        <div>
                          <div
                            onClick={() => {
                              setShowLadder(match);
                            }}
                            className="sc-dRqsoR hEgtIY"
                          >
                            <h1>{match?.runnerName}</h1>
                          </div>

                          <div className="sc-cZihYk hOlWHp">
                            <span
                              _ngcontent-ng-c2652263913=""
                              className="d-block ps-2"
                              style={{
                                color:
                                  parseFloat(
                                    matchData?.find(
                                      (item) =>
                                        item.marketIdExternal ==
                                        match?.marketId
                                    )?.exposure
                                  ) >= 0
                                    ? "green"
                                    : "red",
                              }}
                            >
                              {matchData?.find(
                                (item) =>
                                  item.marketIdExternal == match?.marketId
                              ) &&
                                parseFloat(
                                  matchData?.find(
                                    (item) =>
                                      item.marketIdExternal == match?.marketId
                                  )?.exposure
                                ).toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ alignItems: "center" }}
                        className="sc-exLqqE sc-czeHBz eTlYgY bbKLbo"
                      >
                        <i
                          onClick={() => {
                            navigate(
                              `/game/matchPosition/${eventId}/${
                                match?.runnerName
                              }/${match?.marketId}`,
                              {
                                state: {
                                  isBookmaker: false,
                                  runnerName: match?.runnerName,
                                },
                              }
                            );
                          }}
                          className="fa fa-info-circle"
                        />
                        {match?.status == "SUSPENDED" ||
                        match?.status == "Ball Running" ||
                        match?.status === null ? (
                          <div
                            style={{
                              marginLeft: "12px",
                            }}
                            class="sc-gwsEVw efEdVc"
                          >
                            {match?.status}
                          </div>
                        ) : null}
                        <div type="LAY" className="sc-kRjaKC hoLQnR">
                          <div type="LAY" className="sc-kWvIdS fvpuXi">
                            <div type="LAY" className="sc-cVQvvw jtgJVn">
                              {match?.lay1}
                            </div>
                            <div type="LAY" className="sc-loRuot gNLGlZ">
                              {match?.laySize1}
                            </div>
                          </div>
                        </div>
                        <div type="BACK" className="sc-kRjaKC jwPIDK">
                          <div type="BACK" className="sc-kWvIdS QtCGw">
                            <div type="BACK" className="sc-cVQvvw jtgJVn">
                              {match?.back1}
                            </div>
                            <div type="BACK" className="sc-loRuot gNLGlZ">
                              {match?.backSize1}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {/**/}
            </div>
          </div>
          <div
            _ngcontent-ng-c2652263913=""
            className="custom_table matchdtl_bottm"
          >
            <div
              _ngcontent-ng-c2652263913=""
              className="table-responsive ng-star-inserted"
            >
              <table _ngcontent-ng-c2652263913="" className="table">
                <thead _ngcontent-ng-c2652263913="">
                  <tr _ngcontent-ng-c2652263913="">
                    <th _ngcontent-ng-c2652263913="">SNo</th>
                    {params?.runnerName && (
                      <th _ngcontent-ng-c2652263913="">Run</th>
                    )}
                    <th _ngcontent-ng-c2652263913="">Rate</th>
                    <th _ngcontent-ng-c2652263913="">Amount</th>
                    <th _ngcontent-ng-c2652263913="">Mode</th>
                    <th _ngcontent-ng-c2652263913="">Team</th>
                    <th _ngcontent-ng-c2652263913="">Client</th>
                    <th _ngcontent-ng-c2652263913="">Agent</th>
                    {clientBets &&
                      clientBets?.bets &&
                      clientBets?.bets?.length > 0 &&
                      clientBets?.bets?.[0]?.upperLineExposure?.map(
                        (item) => (
                          <th _ngcontent-ng-c2652263913="">
                            {item?.selectionName}
                          </th>
                        )
                      )}

                    <th _ngcontent-ng-c2652263913="">Date &amp; Time</th>
                  </tr>
                </thead>
                <tbody _ngcontent-ng-c2652263913="">
                  {/**/}
                  {clientBets?.bets
                    ?.filter((item) => {
                      if (location?.state?.isBookmaker == true) {
                        return (
                          item?.runnerName == location?.state?.runnerName
                        );
                      } else if (location?.state?.isBookmaker == false) {
                        return (
                          item?.marketName != "BOOKMAKER_ODDS_1" &&
                          item?.runnerName == location?.state?.runnerName
                        );
                      } else {
                        return item;
                      }
                    })
                    .map((item, index) => (
                      <>
                        <tr key={index}>
                          <td>{index + 1}</td>
                          {params?.runnerName && <td>{item?.run}</td>}
                          <td>{item.rate}</td>

                          <td>{parseFloat(item.amount).toFixed(2)}</td>
                          <td
                            style={{
                              color:
                                item?.marketName == "BOOKMAKER_ODDS_1"
                                  ? item?.back
                                    ? "rgb(3, 117, 204)"
                                    : "rgb(224, 60, 60)"
                                  : item?.back
                                  ? "rgb(3, 117, 204)"
                                  : "rgb(224, 60, 60)",
                            }}
                          >
                            {item?.marketName == "BOOKMAKER_ODDS_1"
                              ? item?.back
                                ? "LAGAI"
                                : "KHAI"
                              : item?.back
                              ? "YES"
                              : "NO"}
                          </td>
                          <td>{item.runnerName}</td>
                          <td>{item.userName}</td>
                          <td>{item?.agent}</td>
                          {item?.upperLineExposure?.map((item) => (
                            <td
                              style={{
                                color:
                                  parseFloat(item?.exposure) < 0
                                    ? "red"
                                    : "green",
                              }}
                            >
                              {parseFloat(item?.exposure).toFixed(2)}
                            </td>
                          ))}

                          <td>
                            {moment(item.placeDate + "Z").format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </td>
                        </tr>
                      </>
                    ))}

                  {/**/}
                  {/**/}
                </tbody>
              </table>
            </div>
            {/**/}
            {/**/}
            {/**/}
          </div>
        </div>
        {/**/}
      </app-match-detail>
      <div
        _ngcontent-ng-c2652263913=""
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        className="modal fade show"
        aria-modal="true"
        role="dialog"
        style={{ display: !showLadder ? "none" : "block" }}
      >
        <div
          _ngcontent-ng-c2652263913=""
          className="modal-dialog modal-dialog-centered"
        >
          <div _ngcontent-ng-c2652263913="" className="modal-content">
            <div _ngcontent-ng-c2652263913="" className="modal-header">
              <h1
                _ngcontent-ng-c2652263913=""
                id="exampleModalLabel"
                className="modal-title fs-5"
              >
                {showLadder?.runnerName}
              </h1>
              <button
                _ngcontent-ng-c2652263913=""
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                className="btn-close"
                onClick={() => setShowLadder(false)}
              />
            </div>
            <div _ngcontent-ng-c2652263913="" className="modal-body">
              <div _ngcontent-ng-c2652263913="" className="custom_table">
                <table
                  _ngcontent-ng-c2652263913=""
                  className="table table-bordered"
                >
                  <thead _ngcontent-ng-c2652263913="">
                    <tr _ngcontent-ng-c2652263913="">
                      <th _ngcontent-ng-c2652263913="" scope="col">
                        Run
                      </th>
                      <th _ngcontent-ng-c2652263913="" scope="col">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody _ngcontent-ng-c2652263913="">
                    {matchData &&
                      matchData
                        ?.find(
                          (item) =>
                            item.marketIdExternal == showLadder?.marketId
                        )
                        ?.runnerPlExposureDTOS?.sort(
                          (a, b) =>
                            parseInt(a.selectionName) -
                            parseInt(b.selectionName)
                        )
                        ?.map((item) => (
                          <tr _ngcontent-ng-c2652263913="">
                            <td _ngcontent-ng-c2652263913="">
                              {item?.selectionName}
                            </td>
                            <td
                              _ngcontent-ng-c2652263913=""
                              style={{
                                color: item?.exposure < 0 ? "red" : "green",
                              }}
                            >
                              {parseFloat(item?.exposure).toFixed(2)}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div _ngcontent-ng-c2652263913="" className="modal-footer">
              <button
                _ngcontent-ng-c2652263913=""
                type="button"
                onClick={() => setShowLadder(false)}
                data-bs-dismiss="modal"
                className="btn btn-primary"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
