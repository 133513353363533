import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getChildUserInfoAPI,
  getClientAPI,
  getClientChildAPI,
} from "../service/UserService";
import { updateCommissionAPI } from "../service/AuthService";
import toast from "react-hot-toast";

export default function ChildLimitUpdate() {
  const params = useParams();
  const [search, setSearch] = useState("");
  const [clientData, setClientData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [clientChildData, setClientChildInfo] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(10);
  const location = useLocation();
  const [userType, setUserType] = useState(params?.userType?.toUpperCase());
  const [userName, setId] = useState(location?.state?.client?.username);
  const [uid, setUId] = useState(location?.state?.client?.uid);
  const [updateCommission, setUpdateCommission] = useState({});
  const [parentData, setParentLimit] = useState({});

  const getClient = async () => {
    const { response } = await getClientChildAPI(
      search,
      currentPage,
      size,
      userName,
      params?.userType.toUpperCase()
    );
    let response_ = response;
    if (response_) {
      response_.childrenUserResponseDTOS = response_.childrenUserResponseDTOSPage.content.sort(
        (a, b) => a.uid - b.uid
      );
      setCurrentPage(response_.childrenUserResponseDTOSPage.number);
      setTotalPages(response_.childrenUserResponseDTOSPage.totalPages);
      setTotalElements(response_.childrenUserResponseDTOSPage.totalElements);
      setSize(response_.childrenUserResponseDTOSPage.size);
    }
    setClientData(response_);
  };

  const getParentinfo = async () => {
    const { response } = await getChildUserInfoAPI(uid);
    let response_ = response;
    if (response_) {
      setParentLimit(response_?.childInfo);
    }
  };

  const handleUpdateCommission = async (type) => {
    let updatedLimit;
    if (type === "Minus") {
      updatedLimit = -updateCommission.currentLimit;
    } else {
      updatedLimit = updateCommission.currentLimit;
    }
    try {
      const { response, code } = await updateCommissionAPI({
        ...updateCommission,
        currentLimit: updatedLimit,
      });
      if (code === 200) {
        toast.success("Limit Updated Successfully");
        getClient();
        window.location.reload();
        setUpdateCommission({
          ...updateCommission,
          currentLimit: "",
        });
      } else {
        toast.error(response);
      }
    } catch (e) {
      toast.error("Something went wrong");
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    getParentinfo();
    getClient();
    setClientChildInfo(location?.state?.client);
  }, [uid, currentPage, size, search, userName, userType]);
  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-update-limit _nghost-ng-c3330665416="">
        <div _ngcontent-ng-c3330665416="" className="page_header">
          <h1 _ngcontent-ng-c3330665416="" className="page_heading">
            {userType} Limit
          </h1>
          <div _ngcontent-ng-c3330665416="" className="breadcrumb_block">
            <nav _ngcontent-ng-c3330665416="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c3330665416="" className="breadcrumb">
                <li _ngcontent-ng-c3330665416="" className="breadcrumb-item">
                  <a _ngcontent-ng-c3330665416="" href="#/dashboard">
                    Home
                  </a>
                </li>
                <li _ngcontent-ng-c3330665416="" className="breadcrumb-item">
                  <a _ngcontent-ng-c3330665416="">{userType} Details</a>
                </li>
                <li
                  _ngcontent-ng-c3330665416=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  {userType} Limit
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          _ngcontent-ng-c3330665416=""
          className="Super_master_details_section agentledger_block"
        >
          <div _ngcontent-ng-c3330665416="" className="custom_table">
            <div _ngcontent-ng-c3330665416="" className="table-responsive">
              <div
                _ngcontent-ng-c3330665416=""
                noValidate=""
                className="ng-untouched ng-pristine ng-invalid"
              >
                <table _ngcontent-ng-c3330665416="" className="table">
                  <thead _ngcontent-ng-c3330665416="">
                    <tr _ngcontent-ng-c3330665416="">
                      <th _ngcontent-ng-c3330665416="">SNo.</th>
                      <th _ngcontent-ng-c3330665416="">Super Agent</th>
                      <th _ngcontent-ng-c3330665416="">Current Limit</th>
                      <th _ngcontent-ng-c3330665416="">Add/Minus Limit</th>
                      <th _ngcontent-ng-c3330665416="">Agent Limit</th>
                      <th _ngcontent-ng-c3330665416="">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody _ngcontent-ng-c3330665416="">
                    {clientData?.childrenUserResponseDTOSPage?.content?.map(
                      (client) => (
                        <tr _ngcontent-ng-c3330665416="">
                          <td _ngcontent-ng-c3330665416="">{client?.uid}</td>
                          <td _ngcontent-ng-c3330665416="">
                            <input
                              _ngcontent-ng-c3330665416=""
                              type="text"
                              readOnly=""
                              value={client?.username + " - " + client?.name}
                              id="name"
                              name="name"
                              className="form-control"
                            />
                          </td>
                          <td _ngcontent-ng-c3330665416="">
                            <input
                              _ngcontent-ng-c3330665416=""
                              type="number"
                              readOnly=""
                              id="name"
                              value={parseFloat(
                                client?.balance + client?.liability
                              ).toFixed()}
                              name="name"
                              className="form-control"
                            />
                          </td>
                          <td _ngcontent-ng-c3330665416="">
                            <input
                              _ngcontent-ng-c3330665416=""
                              type="number"
                              id="name"
                              formcontrolname="addChips"
                              onChange={(e) => {
                                setUpdateCommission({
                                  ...updateCommission,
                                  id: client?.uid,
                                  currentLimit: e.target.value,
                                });
                              }}
                              name="name"
                              className="form-control ng-untouched ng-pristine ng-invalid"
                            />
                          </td>
                          <td _ngcontent-ng-c3330665416="">
                            <input
                              _ngcontent-ng-c3330665416=""
                              readOnly=""
                              type="number"
                              id="name"
                              value={parseFloat(
                                parentData?.parentLimit
                              ).toFixed(2)}
                              name="name"
                              className="form-control"
                            />
                          </td>
                          <td
                            _ngcontent-ng-c3330665416=""
                            style={{ display: "flex" }}
                          >
                            <button
                              onClick={() => handleUpdateCommission("Plus")}
                              _ngcontent-ng-c3330665416=""
                              type="button"
                              className="btn bg_plus"
                            >
                              Add
                            </button>
                            <button
                              onClick={() => handleUpdateCommission("Minus")}
                              _ngcontent-ng-c3330665416=""
                              type="button"
                              className="btn ms-2 red_plus"
                            >
                              Minus
                            </button>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </app-update-limit>
      {/**/}
    </div>
  );
}