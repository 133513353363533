import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getSettledFancyAPI } from "../service/cricket";
import { getChildrenByEventId } from "../service/AuthService";
import { getClientAPI } from "../service/UserService";
import { CASINO_GAMES } from "../utils/constants";

export default function SessionPlusMinusSelect({ isCasino, isMatka }) {
  const params = useParams();
  const location = useLocation();
  const [selected, setSelected] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  var gameType = "cricket";

  const navigate = useNavigate();
  var eventId = params?.id;
  const [settledFancy, setSettledFancy] = useState({});
  const [child, setChild] = useState([]);
  const getSettledFancy = async () => {
    const { response } = await getSettledFancyAPI(eventId);
    setSettledFancy(response);
    if (isMatka) {
      setSelected([params?.id]);
    } else {
      setSelected(response?.markets?.map((i) => i?.marketId));
    }
  };
  const getChild = async () => {
    const { response } = await getChildrenByEventId(eventId);
    setChild(response?.children);
    if (isMatka) {
    }
    setSelectedUsers(response?.children?.map((i) => i?.uid));
  };
  const getChildForCasino = async () => {
    const { response, code } = await getClientAPI("", 0, 100);
    if (code === 200) {
      setChild(response?.childrenUserResponseDTOSPage?.content);

      setSelectedUsers(
        response?.childrenUserResponseDTOSPage?.content?.map((i) => i?.uid)
      );
      if (isMatka) {
        setSelected(["MATKA" + params?.id]);
      } else {
        setSelected(
          Object.keys(CASINO_GAMES).map((i) => eventId + "_" + CASINO_GAMES[i])
        );
      }
    }
  };
  useEffect(() => {
    if (isCasino || isMatka) {
      getChildForCasino();
    } else {
      getChild();
      getSettledFancy();
    }
  }, [eventId]);

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Match &amp; Session Plus Minus Report :{eventId}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">
                  Match &amp; Session Plus Minus Report
                </li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* /.card */}
              <div className="card">
                <div
                  action="/game/i/MatchSessionPlusMinusDisplay/2040"
                  method="POST"
                  id="demoForm"
                >
                  <input
                    type="hidden"
                    name="_csrf"
                    defaultValue="50268335-a36d-4e35-9c3f-1230f127ea34"
                  />
                  <input type="hidden" name="type" defaultValue="s" />
                  <div className="card-header text-center">
                    <div className="container">
                      <div className="row">
                        <div className="col">
                          <h5 className="text-bold">
                            MasterAgent OF :{location?.state?.match?.eventName}
                          </h5>
                        </div>
                        <div className="col">
                          <button
                            onClick={() => {
                              if (isCasino || isMatka) {
                                navigate(
                                  `/game/i/MatchSessionPlusMinusDisplay/casino/MATKA${eventId}`,
                                  {
                                    state: {
                                      selectedUsers,
                                      selected,
                                      match: location?.state?.match,
                                    },
                                  }
                                );
                              } else {
                                navigate(
                                  `/game/i/MatchSessionPlusMinusDisplay/${eventId}`,
                                  {
                                    state: {
                                      selectedUsers,
                                      selected,
                                      match: location?.state?.match,
                                    },
                                  }
                                );
                              }
                            }}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Show
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    {isCasino ? (
                      <div className="form-row">
                        <div className="col-md-8">
                          <table
                            align="left"
                            id="clientTable"
                            className="table table-striped  table-hover table-bordered"
                          >
                            <thead>
                              <tr>
                                <th width="15%">
                                  <div align="center">
                                    <input
                                      type="checkbox"
                                      id="allClient"
                                      checked={
                                        selectedUsers?.length === child?.length
                                      }
                                      onClick={(e) => {
                                        if (e.target.checked) {
                                          setSelectedUsers(
                                            child?.map((i) => i?.uid)
                                          );
                                        } else {
                                          setSelectedUsers([]);
                                        }
                                      }}
                                      defaultValue={1}
                                      defaultChecked=""
                                    />
                                  </div>
                                </th>
                                <th>Master Agent (CODE NAME)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {child?.map((item) => (
                                <tr>
                                  <td>
                                    <div align="center">
                                      <input
                                        checked={selectedUsers?.includes(
                                          item?.uid
                                        )}
                                        type="checkbox"
                                        className="custom-checkbox"
                                        defaultValue='{"id":3639,"name":"Yash"}'
                                        id="names1"
                                        name="names"
                                        onClick={(e) => {
                                          if (e.target.checked) {
                                            setSelectedUsers([
                                              ...selectedUsers,
                                              item?.uid,
                                            ]);
                                          } else {
                                            setSelectedUsers(
                                              selectedUsers.filter(
                                                (i) => i !== item?.uid
                                              )
                                            );
                                          }
                                        }}
                                      />
                                      <input
                                        type="hidden"
                                        name="_names"
                                        defaultValue="on"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    {item?.name} {item?.username}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-4">
                          <table
                            align="left"
                            id="sessionsTable"
                            className="table table-striped  table-hover table-bordered"
                          >
                            <thead>
                              <tr>
                                <th width="15%">
                                  <div align="center">
                                    <input
                                      checked={
                                        selected?.length ===
                                        Object.keys(CASINO_GAMES).map(
                                          (i) => eventId + "_" + CASINO_GAMES[i]
                                        ).length
                                      }
                                      /* onClick={(e) => {
                                    if (e.target.checked) {
                                      setSelected(
                                        settledFancy?.markets?.map(
                                          (i) => i?.marketName
                                        )
                                      );
                                    } else {
                                      setSelected([]);
                                    }
                                  }} */
                                      type="checkbox"
                                      id="allSessions"
                                      defaultValue={1}
                                      defaultChecked=""
                                    />
                                  </div>
                                </th>
                                <th>Game Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(CASINO_GAMES)?.map((item) => (
                                <tr>
                                  <td>
                                    <div align="center">
                                      <input
                                        type="checkbox"
                                        className="custom-checkbox"
                                        defaultValue="6 OVER RUNS DC W"
                                        id="sessions2"
                                        checked={selected?.includes(
                                          eventId + "_" + CASINO_GAMES[item]
                                        )}
                                        name="sessions"
                                      />
                                      <input
                                        type="hidden"
                                        name="_sessions"
                                        defaultValue="on"
                                      />
                                    </div>
                                  </td>
                                  <td
                                    data-column="session_title"
                                    className="bet-place-tbl-td"
                                    style={{ textAlign: "left" }}
                                  >
                                    {CASINO_GAMES[item]}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <div className="form-row">
                        <div className="col-md-8">
                          <table
                            align="left"
                            id="sessionsTable"
                            className="table table-striped  table-hover table-bordered"
                          >
                            <thead>
                              <tr>
                                <th width="15%">
                                  <div align="center">
                                    <input
                                      checked={
                                        selected.length ===
                                        settledFancy?.markets?.length
                                      }
                                      /* onClick={(e) => {
                                    if (e.target.checked) {
                                      setSelected(
                                        settledFancy?.markets?.map(
                                          (i) => i?.marketName
                                        )
                                      );
                                    } else {
                                      setSelected([]);
                                    }
                                  }} */
                                      type="checkbox"
                                      id="allSessions"
                                      defaultValue={1}
                                      defaultChecked=""
                                    />
                                  </div>
                                </th>
                                <th>SESSION (POST DATE)</th>
                                <th>DECLARE</th>
                              </tr>
                            </thead>
                            <tbody>
                              {settledFancy?.markets?.map((item) => (
                                <tr>
                                  <td>
                                    <div align="center">
                                      <input
                                        type="checkbox"
                                        className="custom-checkbox"
                                        defaultValue="6 OVER RUNS DC W"
                                        id="sessions2"
                                        checked={selected.includes(
                                          item?.marketId
                                        )}
                                        name="sessions"
                                        /*  onClick={(e) => {
                                      if (e.target.checked) {
                                        setSelected([
                                          ...selected,
                                          item?.marketId,
                                        ]);
                                      } else {
                                        setSelected(
                                          selected.filter(
                                            (i) => i !== item?.marketId
                                          )
                                        );
                                      }
                                    }} */
                                      />
                                      <input
                                        type="hidden"
                                        name="_sessions"
                                        defaultValue="on"
                                      />
                                    </div>
                                  </td>
                                  <td
                                    data-column="session_title"
                                    className="bet-place-tbl-td"
                                    style={{ textAlign: "left" }}
                                  >
                                    {item?.marketName}
                                  </td>
                                  <td className="bet-place-tbl-td bet-val-cent">
                                    {item?.result}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-4">
                          <table
                            align="left"
                            id="clientTable"
                            className="table table-striped  table-hover table-bordered"
                          >
                            <thead>
                              <tr>
                                <th width="15%">
                                  <div align="center">
                                    <input
                                      type="checkbox"
                                      id="allClient"
                                      checked={
                                        selectedUsers.length === child?.length
                                      }
                                      onClick={(e) => {
                                        if (e.target.checked) {
                                          setSelectedUsers(
                                            child?.map((i) => i?.uid)
                                          );
                                        } else {
                                          setSelectedUsers([]);
                                        }
                                      }}
                                      defaultValue={1}
                                      defaultChecked=""
                                    />
                                  </div>
                                </th>
                                <th>Master Agent (CODE NAME)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {child?.map((item) => (
                                <tr>
                                  <td>
                                    <div align="center">
                                      <input
                                        checked={selectedUsers.includes(
                                          item?.uid
                                        )}
                                        type="checkbox"
                                        className="custom-checkbox"
                                        defaultValue='{"id":3639,"name":"Yash"}'
                                        id="names1"
                                        name="names"
                                        onClick={(e) => {
                                          if (e.target.checked) {
                                            setSelectedUsers([
                                              ...selectedUsers,
                                              item?.uid,
                                            ]);
                                          } else {
                                            setSelectedUsers(
                                              selectedUsers.filter(
                                                (i) => i !== item?.uid
                                              )
                                            );
                                          }
                                        }}
                                      />
                                      <input
                                        type="hidden"
                                        name="_names"
                                        defaultValue="on"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    {item?.name} {item?.username}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
      {/* /.content */}
    </div>
  );
}