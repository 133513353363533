import JSEncrypt from "jsencrypt";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { loginAPI } from "../service/AuthService";
import { PUBLIC_KEY } from "../utils/constants";

import { generateHash } from "../service";
import Loader from "./components/Loader";

function LoginPage() {
  const dispatch = useDispatch();
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setIsLoading] = useState(false);
  const login = async (e) => {
    setIsLoading(true);
    let encryptedPassword = new JSEncrypt();
    encryptedPassword.setPublicKey(PUBLIC_KEY);
    encryptedPassword = encryptedPassword.encrypt(password);
    let fp = await generateHash();
    localStorage.setItem("fingerprint", fp);
    var { data, code, message, response, error } = await loginAPI(
      userName.replace("-", ""),
      encryptedPassword
    );
    if (code === 200) {
      setIsLoading(false);
      dispatch({
        type: "accountData/setUserData",
        payload: response,
      });
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: false,
      });
      dispatch({
        type: "accountData/setChangePopSate",
        payload: response.resetRequired,
      });
    } else if (code == 500) {
      toast.error(response);
      setIsLoading(false);
    } else if (code == 403) {
      setIsLoading(false);
      toast.error(error.description);
    } else if (code == 401) {
      setIsLoading(false);
      toast.error(response);
    } else {
      toast.error("Something went wrong or Account is not active");
    }
  };
  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : null}
      <div className="login-page">
        <div
          className="d-flex align-items-center"
          style={{ height: "100vh", justifyContent: "center" }}
        >
          <div
            style={{
              position: "absolute",
              bottom: "0px",
            }}
            className="login-card"
          >
            <div className="row login-width">
              <div className="col-lg-12">
                <center>
                  <img
                    style={{
                      width: "50%",
                      height: "100px",
                    }}
                    src="logo.png"
                    alt="logo"
                    className="logo"
                  />
                </center>
              </div>
              <div className="m-auto position-relative">
                <form>
                  {/* <span className="cstyle">C</span> */}
                  <input
                    type="text"
                    placeholder="Username"
                    onChange={(e) => setUsername(e.target.value)}
                    className="login-input-field pdleft-4"
                    fullWidth
                    label="Client Code *"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <input
                    type="password"
                    className="login-input-field"
                    fullWidth
                    label="Password *"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                  />
                  <div spacing={2} direction="row">
                    <button
                      variant="contained"
                      fullWidth
                      className=" primary-button text-white"
                      onClick={login}
                      type="button"
                    >
                      Login
                    </button>
                  </div>
                </form>
              </div>
              {/* <Footer /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default LoginPage;
