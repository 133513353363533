import { useToast } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import {
  getCoinHistoryAPI,
  getUsersByTypeAPI,
  searchUserAPI,
} from "../service/AuthService";
import { useLocation, useParams } from "react-router-dom";
import ReactSelect from "react-select";
import { PERMISSION_MAP } from "../utils/constants";
import { useSelector } from "react-redux";
export default function AccountStatement() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [type, setType] = useState("ALL");
  const location = useLocation();
  const [filter, setFilter] = useState(null);
  const [commissionFilter] = useState([
    "commission",
    "loss",
    "profit",
    "winner",
  ]);
  const [userName, setUserName] = useState(
    location.state?.userName
      ? {
          value: location.state?.userName,
          label: location.state?.userName,
        }
      : null
  );
  const [fromDate, setFromDate] = useState(
    moment()
      .subtract(7, "d")
      .format("YYYY-MM-DD")
  );

  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [search, setSearch] = useState("");
  const [userType, setUserType] = useState("CLIENT");
  const [users, setUsers] = useState([]);

  const { id } = useParams();
  const toast = useToast();
  const getAccountStatement = async () => {
    let payload = {
      startDate: new Date(fromDate).getTime(),
      endDate: new Date(toDate).getTime(),
    };

    const { response, message, code } = await getCoinHistoryAPI(
      id ? id : userName?.value,
      payload.startDate,
      payload.endDate
    );
    if (code == 200) {
      setData(
        response.coinHistory.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      );
    } else {
      toast({
        title: "Error",
        description: response,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const getUsersByType = async () => {
    const { response, code } = await getUsersByTypeAPI(
      userType?.toLocaleUpperCase()
    );
    if (code === 200) {
      setUsers(response?.users);
    }
  };
  useEffect(() => {
    getUsersByType();
  }, [userType]);
  const filterObjectsByKeywords = (array, keywords) => {
    if (!keywords) return array;
    if (keywords == "ACCOUNT")
      return array.filter((obj) => {
        return !commissionFilter?.some((keyword) =>
          obj.description.toLowerCase().includes(keyword.toLowerCase())
        );
      });
    return array.filter((obj) => {
      return keywords?.some((keyword) =>
        obj.description.toLowerCase().includes(keyword.toLowerCase())
      );
    });
  };
  useEffect(() => {
    if (id) {
      getAccountStatement();
    }
    if (type == "LIMIT") {
      setFilter("ACCOUNT");
    }
  }, [page, limit, type, userName, fromDate, toDate, search]);
  const userData = useSelector((state) => state.account.userData);

  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-report-details _nghost-ng-c219872124="">
        <div _ngcontent-ng-c219872124="" className="page_header">
          <h1 _ngcontent-ng-c219872124="" className="page_heading">
            Report Update Details
          </h1>
          <div _ngcontent-ng-c219872124="" className="breadcrumb_block">
            <nav _ngcontent-ng-c219872124="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c219872124="" className="breadcrumb">
                <li _ngcontent-ng-c219872124="" className="breadcrumb-item">
                  <a _ngcontent-ng-c219872124="" href="#/dashboard">
                    Home
                  </a>
                </li>
                <li
                  _ngcontent-ng-c219872124=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  Report Update Details
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          _ngcontent-ng-c219872124=""
          className="Super_master_details_section agentledger_block"
        >
          <form
            _ngcontent-ng-c219872124=""
            noValidate=""
            className="row g-lg-3 g-2 align-items-end ng-untouched ng-pristine ng-valid"
          >
            <div
              _ngcontent-ng-c219872124=""
              className="col-6 col-md-4 col-lg-3"
            >
              <div
                _ngcontent-ng-c219872124=""
                className="input_block position-relative"
              >
                <label _ngcontent-ng-c219872124="">User Type</label>
                <ReactSelect
                  options={PERMISSION_MAP[
                    userData?.userType?.toUpperCase()
                  ]?.map((type) => {
                    return {
                      label: type,
                      value: type,
                    };
                  })}
                  onChange={(e) => {
                    setUserType(e.value);
                  }}
                />
                {/**/}
              </div>
            </div>
            <div
              _ngcontent-ng-c219872124=""
              className="col-6 col-md-4 col-lg-3"
            >
              <div
                _ngcontent-ng-c219872124=""
                className="input_block position-relative"
              >
                <label _ngcontent-ng-c219872124="">User</label>
                <ReactSelect
                  onChange={(e) => setUserName(e)}
                  options={
                    users?.length > 0
                      ? users.map((user) => {
                          return {
                            label: user.userName + " - " + user.name,
                            value: user.userId,
                          };
                        })
                      : []
                  }
                />
                {/**/}
              </div>
            </div>

            <div
              _ngcontent-ng-c219872124=""
              className="col-6 col-md-4 col-lg-3"
            >
              <div _ngcontent-ng-c219872124="" className="input_block">
                <label _ngcontent-ng-c219872124="" htmlFor="type">
                  Date From
                </label>
                <input
                  _ngcontent-ng-c219872124=""
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  formcontrolname="FromDate"
                  className="form-control ng-untouched ng-pristine ng-valid"
                />
              </div>
            </div>
            <div
              _ngcontent-ng-c219872124=""
              className="col-6 col-md-4 col-lg-3"
            >
              <div _ngcontent-ng-c219872124="" className="input_block">
                <label _ngcontent-ng-c219872124="" htmlFor="type">
                  Date To
                </label>
                <input
                  _ngcontent-ng-c219872124=""
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  formcontrolname="EndDate"
                  className="form-control ng-untouched ng-pristine ng-valid"
                />
              </div>
            </div>
            <div
              _ngcontent-ng-c219872124=""
              className="col-6 col-md-4 col-lg-3"
            >
              <div _ngcontent-ng-c219872124="" className="input_block">
                <label _ngcontent-ng-c219872124="" htmlFor="type">
                  Report Type{" "}
                </label>
                <select
                  _ngcontent-ng-c219872124=""
                  formcontrolname="reportType"
                  onChange={(e) => setType(e.target.value)}
                  className="form-control form-select ng-untouched ng-pristine ng-valid"
                >
                  <option _ngcontent-ng-c219872124="" value="">
                    Select Type
                  </option>
                  <option _ngcontent-ng-c219872124="" value={"LIMIT"}>
                    Limit
                  </option>
                  <option _ngcontent-ng-c219872124="" value={3}>
                    commission
                  </option>
                  <option _ngcontent-ng-c219872124="" value={4}>
                    status
                  </option>
                  <option _ngcontent-ng-c219872124="" value={5}>
                    bet status
                  </option>
                </select>
              </div>
            </div>

            <div
              _ngcontent-ng-c219872124=""
              className="col-6 col-md-6 col-lg-3"
            >
              <div _ngcontent-ng-c219872124="" className="input_block">
                <button
                  _ngcontent-ng-c219872124=""
                  type="button"
                  onClick={getAccountStatement}
                  className="btn btn-primary"
                  disabled=""
                >
                  Show Reports
                </button>
              </div>
            </div>
          </form>
        </div>
        <div _ngcontent-ng-c219872124="" className="custom_table">
          <div _ngcontent-ng-c219872124="" className="table-responsive">
            <table _ngcontent-ng-c219872124="" className="table">
              <thead _ngcontent-ng-c219872124="">
                <tr _ngcontent-ng-c219872124="">
                  {data &&
                    data.length > 0 &&
                    Object.keys(data[0]).map((key, index) =>
                      key !== "id" && key != "userId" && key != "marketId" ? (
                        <th _ngcontent-ng-c219872124="" key={index}>
                          {key?.toUpperCase()}
                        </th>
                      ) : null
                    )}
                </tr>
              </thead>
              <tbody
                style={{
                  backgroundColor: "white",
                }}
                _ngcontent-ng-c219872124=""
              >
                {data &&
                  data.length > 0 &&
                  filterObjectsByKeywords(data, filter).map((item, index) => {
                    return (
                      <tr _ngcontent-ng-c219872124="" key={index}>
                        {Object.keys(item).map((key, index) =>
                          key !== "id" &&
                          key !== "userId" &&
                          key != "marketId" ? (
                            <td _ngcontent-ng-c219872124="" key={index}>
                              {key == "createdAt"
                                ? moment(item[key] + "Z").format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                : item[key]}
                            </td>
                          ) : null
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </app-report-details>
      {/**/}
    </div>
  );
}
