import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  accountLogAPI,
  getBetsFromAccountLogs,
  getCollectionReportAPI,
} from "../service/AuthService";
import moment from "moment";
import { color } from "framer-motion";

export default function Ledger({ isCashLedger, isCollectionReport }) {
  const params = useParams();
  const { id } = params;
  const location = useLocation();
  const [isLenaDena, setIsLenaDena] = React.useState(
    location.state?.isLenaDena
  );
  const [payload, setPayload] = React.useState({
    endDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    startDate: moment()
      .subtract(7, "d")
      .format("YYYY-MM-DD HH:mm:ss"),
    pageSize: 100,
    search: "",

    userName: location.state?.client?.username,
  });
  const [ledgerPayload, setLedgerPayload] = React.useState({
    pageSize: 50,
    pageNumber: 0,
  });

  const [collectionReportMapping, setCollectionReportMapping] = React.useState({
    lenaHai: [],
    denaHai: [],
  });

  const ledgerQuery = useQuery(
    "ledger",
    () => accountLogAPI(ledgerPayload, location.state?.isOld),
    {
      enabled: !isCollectionReport,
    }
  );
  const getCollectionReport = async () => {
    const { response, code } = await getCollectionReportAPI(
      payload.startDate,
      payload.endDate,
      location.state?.isLenaDena ? "" : params?.userType,
      location.state?.isLenaDena ? params?.userType : ""
    );
    if (code === 200) {
      let data = response?.collectionReport;
      let lenaHai = data?.filter((item) => item?.balance > 0);
      let denaHai = data?.filter((item) => item?.balance < 0);

      setCollectionReportMapping({
        lenaHai,
        denaHai,
      });
    }
  };
  useEffect(() => {
    if (isCollectionReport) {
      getCollectionReport();
    }
  }, [params?.userType, isCollectionReport]);

  const isMobile = window.innerWidth < 768;

  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-myledger _nghost-ng-c1578323060="">
        <div _ngcontent-ng-c1578323060="" className="page_header">
          <h1 _ngcontent-ng-c1578323060="" className="page_heading">
            My Ledger
          </h1>
          <div _ngcontent-ng-c1578323060="" className="breadcrumb_block">
            <nav _ngcontent-ng-c1578323060="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c1578323060="" className="breadcrumb">
                <li _ngcontent-ng-c1578323060="" className="breadcrumb-item">
                  <a _ngcontent-ng-c1578323060="" href="#/dashboard">
                    Home
                  </a>
                </li>
                <li _ngcontent-ng-c1578323060="" className="breadcrumb-item">
                  <a
                    _ngcontent-ng-c1578323060=""
                    href="#/ledger/ledger-dashboard"
                  >
                    Ledger
                  </a>
                </li>
                <li
                  _ngcontent-ng-c1578323060=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  My Ledger
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          _ngcontent-ng-c1578323060=""
          className="Super_master_details_section"
        >
          <div
            _ngcontent-ng-c1578323060=""
            className="sportname_search d-block"
          >
            <div
              _ngcontent-ng-c1578323060=""
              className="row align-items-end g-3"
            >
              <div
                _ngcontent-ng-c1578323060=""
                className="col-lg-3 col-md-4 col-6"
              >
                <div _ngcontent-ng-c1578323060="" className="input_block">
                  <label _ngcontent-ng-c1578323060="">Date From</label>
                  <input
                    _ngcontent-ng-c1578323060=""
                    type="date"
                    id="fromDate"
                    onChange={(e) =>
                      setLedgerPayload({
                        ...ledgerPayload,
                        fromDate: e.target.value,
                      })
                    }
                    value={moment(ledgerPayload?.fromDate).format("YYYY-MM-DD")}
                    name="fromDate"
                    className="form-control ng-untouched ng-pristine ng-valid"
                  />
                </div>
              </div>
              <div
                _ngcontent-ng-c1578323060=""
                className="col-lg-3 col-md-4 col-6"
              >
                <div _ngcontent-ng-c1578323060="" className="input_block">
                  <label _ngcontent-ng-c1578323060="">Date To</label>
                  <input
                    _ngcontent-ng-c1578323060=""
                    type="date"
                    value={moment(ledgerPayload?.toDate).format("YYYY-MM-DD")}
                    onChange={(e) =>
                      setLedgerPayload({
                        ...ledgerPayload,
                        toDate: e.target.value,
                      })
                    }
                    id="toDate"
                    name="toDate"
                    className="form-control ng-untouched ng-pristine ng-valid"
                  />
                </div>
              </div>
              <div
                _ngcontent-ng-c1578323060=""
                className="col-lg-3 col-md-4 col-6"
              >
                <div _ngcontent-ng-c1578323060="" className="input_block">
                  <button
                    _ngcontent-ng-c1578323060=""
                    type="button"
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div _ngcontent-ng-c1578323060="" className="custom_table">
            <div _ngcontent-ng-c1578323060="" className="table-responsive">
              <table _ngcontent-ng-c1578323060="" className="table">
                <thead _ngcontent-ng-c1578323060="">
                  <tr _ngcontent-ng-c1578323060="">
                    <th _ngcontent-ng-c1578323060="">SNo.</th>
                    <th _ngcontent-ng-c1578323060="">Date</th>
                    <th _ngcontent-ng-c1578323060="">Debit</th>
                    <th _ngcontent-ng-c1578323060="">Credit</th>
                    <th _ngcontent-ng-c1578323060="">Balance</th>
                    <th _ngcontent-ng-c1578323060="">Payment Type</th>
                    <th _ngcontent-ng-c1578323060="">Remark</th>
                  </tr>
                </thead>
                <tbody _ngcontent-ng-c1578323060="">
                  <tr _ngcontent-ng-c1578323060="" className="bd-blue-100">
                    <td _ngcontent-ng-c1578323060="" colSpan={2}>
                      <b _ngcontent-ng-c1578323060="">Total Amount</b>
                    </td>
                    <td
                      _ngcontent-ng-c1578323060=""
                      className={
                        parseFloat(
                          ledgerQuery?.data?.response?.reduce(
                            (acc, item) => acc + item?.debitInfo,
                            0
                          )
                        ) < 0
                          ? "mainus_value"
                          : "plus_value"
                      }
                    >
                      <strong _ngcontent-ng-c1578323060="">
                        {parseFloat(
                          ledgerQuery?.data?.response?.reduce(
                            (acc, item) => acc + item?.debitInfo,
                            0
                          )
                        ).toFixed(2)}
                      </strong>
                    </td>
                    <td
                      _ngcontent-ng-c1578323060=""
                      className={
                        parseFloat(
                          ledgerQuery?.data?.response?.reduce(
                            (acc, item) => acc + item?.creditInfo,
                            0
                          )
                        ) < 0
                          ? "mainus_value"
                          : "plus_value"
                      }
                    >
                      <strong _ngcontent-ng-c1578323060="">
                        {parseFloat(
                          ledgerQuery?.data?.response?.reduce(
                            (acc, item) => acc + item?.creditInfo,
                            0
                          )
                        ).toFixed(2)}
                      </strong>
                    </td>
                    <td
                      _ngcontent-ng-c1578323060=""
                      style={{
                        color:
                          parseFloat(
                            ledgerQuery?.data?.response?.reduce(
                              (acc, item) => acc + item?.updatedBalance,
                              0
                            )
                          ) < 0
                            ? "blue"
                            : "red",
                      }}
                    >
                      <strong _ngcontent-ng-c1578323060="">
                        {parseFloat(
                          ledgerQuery?.data?.response[0]?.updatedBalance
                        ).toFixed(2)}
                      </strong>
                    </td>
                    <td _ngcontent-ng-c1578323060="">&nbsp;</td>
                    <td _ngcontent-ng-c1578323060="" />
                  </tr>
                  {ledgerQuery?.data?.response
                    ?.sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                    ?.map((item, index) => (
                      <tr _ngcontent-ng-c1578323060="">
                        <td _ngcontent-ng-c1578323060="">
                          {item?.accountLogId}
                        </td>
                        <td _ngcontent-ng-c1578323060="">
                          <div
                            _ngcontent-ng-c1578323060=""
                            className="Date_time_box"
                          >
                            {moment(
                              item.createdAt,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("DD-MM-YYYY HH:mm:ss")}
                          </div>
                        </td>
                        <td
                          _ngcontent-ng-c1578323060=""
                          className={
                            parseFloat(item?.debitInfo) < 0
                              ? "mainus_value"
                              : "plus_value"
                          }
                        >
                          {item?.debitInfo &&
                            parseFloat(item?.debitInfo).toFixed(2)}{" "}
                        </td>
                        <td
                          _ngcontent-ng-c1578323060=""
                          className={
                            parseFloat(item?.creditInfo) < 0
                              ? "mainus_value"
                              : "plus_value"
                          }
                        >
                          {" "}
                          {item?.creditInfo &&
                            parseFloat(item?.creditInfo).toFixed(2)}
                        </td>
                        <td
                          _ngcontent-ng-c1578323060=""
                          className={
                            parseFloat(item?.updatedBalance) < 0
                              ? "mainus_value"
                              : "plus_value"
                          }
                        >
                          {item?.updatedBalance &&
                            parseFloat(item?.updatedBalance).toFixed(2)}
                        </td>
                        <td _ngcontent-ng-c1578323060="">{item?.sport}</td>
                        <td _ngcontent-ng-c1578323060="">{item?.comment}</td>
                      </tr>
                    ))}
                  {/**/}
                  {/**/}
                </tbody>
                {/**/}
                {/**/}
              </table>
            </div>
          </div>
        </div>
      </app-myledger>
      {/**/}
    </div>
  );
}
